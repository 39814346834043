



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyReview } from "../../interfaces";
import RatingButton from "../ratingButton/RatingButton.vue";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn, RatingButton }
})
export default class Review extends Vue {
  @Prop() readonly review!: CompanyReview;
  @Prop({ default: true }) readonly isTitle!: boolean;
  @Prop({ default: "#EFF2FF" }) readonly backgroundColor!: string;

  get teg() {
    if (this.review.tags) {
      return this.review.tags.map(e => `#${e.name}`).join(" ");
    } else {
      return "";
    }
  }

  mounted() {
    // console.log(this.review);
  }
}
