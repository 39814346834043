























import { Component, Prop, Vue } from "vue-property-decorator";
import { SearchSelects } from "@/interfaces";

@Component({})
export default class TecFilter extends Vue {
  @Prop({ default: undefined }) readonly item!: SearchSelects;

  get value() {
    return this.item.isSelected;
  }
  set value(val) {
    this.$emit("selectFilter", val !== undefined ? val : false, this.item.id);
  }
}
