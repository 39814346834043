


























































import { Component, Prop, Vue } from "vue-property-decorator";
import { CasesInterface } from "@/interfaces";

@Component({})
export default class ProjectPreview extends Vue {
  @Prop({}) readonly card!: CasesInterface;
}
