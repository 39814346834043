








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ArticleLid extends Vue {
  @Prop({ default: undefined }) readonly text!: string;
}
