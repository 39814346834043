
































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyReview } from "@/interfaces";
import RatingButton from "@/components/ratingButton/RatingButton.vue";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn, RatingButton }
})
export default class ReviewItem extends Vue {
  @Prop() readonly data!: CompanyReview;

  public inputReview(data: CompanyReview) {
    this.$emit("inputReview", data);
  }
}
