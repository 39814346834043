












import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class TecBlockQuote extends Vue {
  @Prop() readonly data?: string;
}
