
















import { Component, Vue } from "vue-property-decorator";
import Articles from "@/components/Articles.vue"; // @ is an alias to /src
import { Meta } from "@/decorators/vue-meta-decorator";
import { TecArticle, TagsInterface, ArticleType } from "@/interfaces";
import { namespace } from "vuex-class";
import { i18n } from "@/main";

const articles = namespace("Articles");

@Component({
  components: {
    Articles,
  },
})
export default class ArticlesVue extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: `${i18n.t("title.articles")} — TECMAN.RU`,
    };
  }

  @articles.State
  public articles!: Array<TecArticle>;
  @articles.Action
  public getArticles!: () => void;
  @articles.State
  public tags!: Array<TagsInterface>;
  @articles.Action
  public updateTags!: (newVal: TagsInterface[]) => void;
  @articles.Action
  public updateTypes!: (newVal: ArticleType) => void;
  @articles.State
  public artTypes!: ArticleType[];
  get title(): string {
    return `${i18n.t("title.articles")}`;
  }
  public showAllTags() {
    const allTags = this.tags.map(function (item: TagsInterface) {
      item.isSelected = true;
      return item;
    });
    this.updateTags(allTags);
    setTimeout(this.getArticles, 100);
  }
  public resetTags() {
    const allTags = this.tags.map(function (item: TagsInterface) {
      item.isSelected = false;
      return item;
    });
    this.updateTags(allTags);
    setTimeout(this.getArticles, 100);
  }
  public updTags(newVal: TagsInterface) {
    const lcltabs = this.tags.map(function (item: TagsInterface) {
      return item.name.match(newVal.name) ? newVal : item;
    });
    this.updateTags(lcltabs);
    setTimeout(this.getArticles, 100);
  }
  public updTypes(newVal: ArticleType) {
    this.updateTypes(newVal);
    setTimeout(this.getArticles, 100);
  }
  // get title(): string {
  //   return {
  //     i18n.t("title.homeTitle"),
  //   };
  // }
  mounted() {
    this.getArticles();
  }
  updated() {
    this.getArticles();
  }
  destroyed() {
    const ttag = [...this.tags];
    ttag.forEach((item) => {
      item.isSelected ? (item.isSelected = false) : "";
    });
    this.updateTags(ttag);
    this.getArticles();
  }

  // public articlesList = [
  //   {
  //     title: "почему так дорого?",
  //     hashString: "#desktop #МФО #хэштег #разработка",
  //     content: "string",
  //     img: "creditCard.png",
  //     desc: "Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений. Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений."
  //   },
  //   {
  //     title: "почему так дорого?",
  //     hashString: "#desktop #МФО #хэштег #разработка",
  //     content: "string",
  //     img: "creditCard.png",
  //     desc: "Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений. Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений."
  //   },
  //   {
  //     title: "почему так дорого?",
  //     hashString: "#desktop #МФО #хэштег #разработка",
  //     content: "string",
  //     img: "creditCard.png",
  //     desc: "Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений. Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений."
  //   }
  // ]
  get crumbs() {
    return [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/",
        link: true,
      },
      {
        text: i18n.t("title.articles"),
        disabled: true,
        href: "",
        link: true,
      },
    ];
  }
}
