






import { Component, Vue } from "vue-property-decorator";
import NotFound from "@/components/NotFound.vue"; // @ is an alias to /src
import { Meta } from "@/decorators/vue-meta-decorator";

@Component({
  components: {
    NotFound
  }
})
export default class NotExist extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: "Страница не найдена — TECMAN.RU"
    };
  }
}
