

















import { Component, Vue } from "vue-property-decorator";
import { Meta } from "@/decorators/vue-meta-decorator";
import ReviewsList from "../components/reviews/ReviewsList.vue";
import { namespace } from "vuex-class";
import { i18n } from "@/main";

import {
  CompanyReview,
  SearchSelects,
  Selects,
  TagsInterface
} from "@/interfaces";

const filters = namespace("Filters");
const Reviews = namespace("Reviews");
const Modal = namespace("Modal");

@Component({
  components: {
    ReviewsList
  }
})
export default class ReviewPageView extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: `${i18n.t("title.feedback")} — TECMAN.RU`
    };
  }
  @filters.State
  public selectList!: SearchSelects;
  @filters.Action
  public updateSelectedItem!: (payload: {
    SelectedItem: Selects;
    id: number;
  }) => void;
  @filters.Action
  public updateSelectionList!: () => void;

  @Reviews.Action
  public getReviews!: () => void;
  @Reviews.Action
  public updateReview!: (newVal: CompanyReview) => void;
  @Reviews.State
  public reviews!: Array<CompanyReview>;
  @Reviews.State
  public tags!: Array<TagsInterface>;
  @Reviews.Action
  public updateTags!: (newVal: TagsInterface[]) => void;

  @Modal.Action
  public ModalType!: (ModalType: string) => void;
  @Modal.Action
  public showModal!: (switcher: boolean) => void;

  private body = document.body;

  public inputReview(data: CompanyReview) {
    console.log(data);
    this.updateReview(data);
    if (data && data.project)
      this.$router.push(`feedback/${data.project.path}`);
  }

  public leaveReview() {
    this.showModal(true);
    this.ModalType("review");
    this.body.classList.add("noscroll");
  }
  public openFilters() {
    console.log("open Filters");
  }

  public updTags(newVal: TagsInterface) {
    const lcltabs = this.tags.map(function(item: TagsInterface) {
      return item.name.match(newVal.name) ? newVal : item;
    });
    this.updateTags(lcltabs);
    setTimeout(this.getReviews, 1000);
  }

  public selectAllTags() {
    const tags = [...this.tags];
    tags.forEach(e => {
      e.isSelected = true;
      this.updTags(e);
    });
    console.log("selected all tags", tags);
  }

  mounted() {
    this.getReviews();
    this.updateSelectionList();
  }
  updated() {
    this.getReviews();
    this.updateSelectionList();
  }

  get crumbs(): object {
    return [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/"
      },
      {
        text: i18n.t("title.feedback"),
        disabled: true,
        href: ""
      }
    ];
  }
}
