






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Crumb, TecArticle } from '@/interfaces';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import TecBlockQuote from '@/components/blockquote/TecBlockQuote.vue';
import ArticleLid from '@/components/article/ArticleLid.vue';
import Crumbs from '@/components/crumbs/Crumbs.vue';

@Component({
  components: { ArticleLid, TecBlockQuote, PageHeader, Crumbs },
})
export default class Article extends Vue {
  vHtml(vHtml: any) {
    throw new Error('Method not implemented.');
  }
  @Prop({ default: undefined }) readonly article!: TecArticle;
  @Prop({ default: undefined }) readonly crumbs!: Array<Crumb>;

  get hashString() {
    return this.article.tags?.map((e) => `#${e.name}`).join(', ');
  }
  get crumbsRefarm() {
    const crumbsArticle = [...this.crumbs];
    crumbsArticle[2].text = this.article ? this.article.title : '...';
    return crumbsArticle;
  }
}
