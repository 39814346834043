








import { Component, Prop, Vue } from "vue-property-decorator";
import { TagsInterface } from "@/interfaces";

@Component({})
export default class Tags extends Vue {
  @Prop({ default: [] }) readonly tag!: TagsInterface;

  get value() {
    return this.tag;
  }

  public select() {
    this.$emit("input", { name: this.value.name, isSelected: true });
  }
  public unselect() {
    this.$emit("input", { name: this.value.name, isSelected: false });
  }
}
