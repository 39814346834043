

























import { Component, Prop, Vue } from "vue-property-decorator";

import { CasesInterface } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn }
})
export default class TecCases extends Vue {
  @Prop() readonly cases?: CasesInterface;
}
