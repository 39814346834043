






















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn }
})
export default class ProjectCalc extends Vue {
  @Prop({ default: false }) readonly isMan!: boolean;

  public title =
    "получите детальный РАСЧЕТ ПРОЕКТА <br /> ПО РАЗРАБОТКЕ CRM и ERP-СИСТЕМЫ";
  public text =
    "В течение 2-х рабочих дней разработаем подробную смету проекта с описанием системы учета, процессов и платформы.<br />Закажите подробный расчет проекта, заполнив форму ниже:";
  public name = "";
  public phone = "";
}
