



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ArticleType, Crumb, TagsInterface, TecArticle } from '@/interfaces';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Tags from '@/components/tags/Tags.vue';
import TecBtn from '@/components/tecBtn/TecBtn.vue';
import Filters from '@/components/modal/filters.vue';

@Component({
  components: { Filters, TecBtn, PageHeader, Tags },
})
export default class Articles extends Vue {
  @Prop() readonly articlesList!: Array<TecArticle>;
  @Prop() readonly crumbs!: Array<Crumb>;
  @Prop() readonly title!: string;
  @Prop({ default: undefined }) readonly tags!: TagsInterface[];
  @Prop() readonly artTypes!: ArticleType[];

  public activeClass = '';
  public isModal = false;
  private body = document.body;

  public showModal() {
    this.isModal = true;
    this.body.classList.add('noscroll');
  }
  public resetTegs() {
    this.closeModal();
    this.$emit('resetTags');
  }
  public closeModal() {
    this.isModal = false;
    this.body.classList.remove('noscroll');
  }

  public showAllTags() {
    this.$emit('showAllTags');
  }
  public selectTag(newVal: TagsInterface) {
    this.$emit('selectTag', newVal);
  }
  teg(article: TecArticle) {
    if (article.tags) {
      return article.tags.map((e) => `#${e.name}`).join(', ');
    } else {
      return '';
    }
  }
  dateString(stamp: string): string {
    const st = parseInt(stamp) * 1000;
    const ts = new Date(st);
    let month = ts.toLocaleString('default', { month: 'long' });
    const day = ts.toLocaleString('default', { day: 'numeric' });
    if (month === 'март' || month === 'август') month = month + 'а';
    else month = month.replace(/.$/, 'я');
    return day + ' ' + month;
  }
}
