





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CasesInterface,
  Crumb,
  SearchSelects,
  TagsInterface,
} from "@/interfaces";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import Review from "@/components/reviews/Review.vue";
import ProjectCalc from "@/components/projectСalculation/ProjectCalc.vue";
import TecFilter from "@/components/filter/TecFilter.vue";
import ProjectPreview from "@/components/progectPreview/ProjectPreview.vue";
import Tags from "@/components/tags/Tags.vue";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import Filters from "@/components/modal/filters.vue";
import CaseFilters from "@/components/modal/caseFilters.vue";
import { i18n } from "@/main";

@Component({
  components: {
    CaseFilters,
    Filters,
    TecBtn,
    Tags,
    ProjectPreview,
    TecFilter,
    ProjectCalc,
    Review,
    PageHeader,
  },
})
export default class Projects extends Vue {
  @Prop({
    default: undefined,
  })
  readonly crumbs?: Array<Crumb>;

  @Prop({ default: undefined }) readonly cards!: Array<CasesInterface>;
  @Prop({ default: undefined }) readonly selectList!: SearchSelects[];
  @Prop({ default: undefined }) readonly tags!: TagsInterface[];

  public isModal = false;
  public isCaseModal = false;

  private body = document.body;

  public showModal() {
    this.isModal = true;
    this.body.classList.add("noscroll");
  }
  public resetTegs() {
    this.closeModal();
    this.closeCaseModal();
    this.$emit("resetTags");
  }
  public closeModal() {
    this.isModal = false;
    this.body.classList.remove("noscroll");
  }

  public showCaseModal() {
    this.isCaseModal = true;
    this.body.classList.add("noscroll");
  }
  public closeCaseModal() {
    this.isCaseModal = false;
    this.body.classList.remove("noscroll");
  }

  get modifiedCards(): CasesInterface[][] {
    let modified: CasesInterface[][] = [];
    if (this.cards.length > 6) {
      modified = this.splitArray(this.cards);
    }
    return modified;
  }
  public chusenCard(card: CasesInterface) {
    this.$emit("inputCard", card);
  }
  public selectTag(newVal: TagsInterface) {
    this.$emit("selectTag", newVal);
  }

  public showAllTags() {
    this.$emit("showAllTags");
  }

  private splitArray(array: Array<CasesInterface>): CasesInterface[][] {
    let count;
    let start = 0;
    this.$vuetify.breakpoint.lgAndUp
      ? (count = 5)
      : (count = this.getRandomInt(4, 5));
    let end = count;
    const childArray = [];

    while (array.length - start > 0) {
      end = start + count;
      childArray.push(array.slice(start, end));
      start = start + count;
      count = this.getRandomInt(5, count);
    }
    if (childArray[childArray.length - 1].length === 1) {
      // if last array length is 1, transfer element to the penultimate array
      if (childArray[childArray.length - 2].length <= 5) {
        childArray[childArray.length - 2].push(
          childArray[childArray.length - 1][0]
        );
        childArray.splice(childArray.length - 1, 1);
      } else {
        childArray[childArray.length - 1].push(
          childArray[childArray.length - 2][
            childArray[childArray.length - 2].length
          ]
        );
        childArray[childArray.length - 2].splice(
          childArray[childArray.length - 2].length,
          1
        );
      }
    }
    return childArray;
  }

  private getRandomInt(max: number, before: number): number {
    let val = before;
    if (this.$vuetify.breakpoint.mdOnly) {
      val = 2;
    } else {
      while (val === before || val < 2) {
        val = Math.floor(Math.random() * Math.floor(max) + 1);
      }
    }
    return val;
  }

  public calcGrids(ndx: number, arr: Array<CasesInterface>): string {
    let GridClass = "";
    switch (arr.length) {
      case 2:
        ndx === 1 ? (GridClass = "horiz2") : "";
        break;
      case 1 || 3 || 6:
        break;
      case 4:
        ndx === 1 ? (GridClass = "horiz2") : "";
        ndx === 3 ? (GridClass = "horiz1") : "";
        break;
      case 5:
        ndx === 2 ? (GridClass = "vert") : "";
        break;
      default:
        break;
    }
    return GridClass;
  }

  get title(): string {
    return `${i18n.t("title.portfolio")}`;
  }

  public addFilter(isSelected: string, id: number) {
    this.$emit("filterSelected", { isSelected, id });
  }

  public deleteFilter(item: SearchSelects) {
    this.$emit("filterSelected", { isSelected: false, id: item.id });
  }
}
