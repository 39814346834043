






















































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CasesInterface, CompanyReview, Crumb } from '@/interfaces';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Review from '@/components/reviews/Review.vue';
import ProjectCalc from '@/components/projectСalculation/ProjectCalc.vue';
import ImageCarousel from '@/components/imageCarousel/ImageCarousel.vue';
import TecBlockQuote from '@/components/blockquote/TecBlockQuote.vue';
import { i18n } from '@/main';
import Crumbs from '@/components/crumbs/Crumbs.vue';

@Component({
  components: {
    TecBlockQuote,
    ImageCarousel,
    ProjectCalc,
    Review,
    PageHeader,
    Crumbs,
  },
})
export default class Project extends Vue {
  [x: string]: any;
  @Prop({
    default: () => [
      {
        text: i18n.t('title.home'),
        disabled: false,
        href: '/',
      },
      {
        text: i18n.t('title.titleCases'),
        disabled: false,
        href: '/projects',
      },
      {
        text: 'Проект Ависто',
        disabled: true,
        href: '',
      },
    ],
  })
  crumbs?: Array<Crumb>;

  @Prop({ default: null }) projectData?: CasesInterface;
  @Prop() readonly review?: CompanyReview;

  public showImg(slideData: string) {
    this.$emit('imgClick', slideData);
  }

  public resultList(n: number): Array<string> | string {
    const half =
      this.projectData && this.projectData.result
        ? Math.ceil(this.projectData.result.length / 2)
        : 0;
    return this.projectData && this.projectData.result
      ? n < 2
        ? this.projectData.result.slice(0, half)
        : this.projectData.result.slice(half - this.projectData.result.length)
      : '';
  }
}
