<template>
  <div class="photography" @click="$emit('imgClick', slideData)">
    <div class="portfolio_item">
      <img :src="slideData" alt="image" class="img-responsive" />
      <div class="portfolio_item_hover">
        <div class="portfolio-border clearfix">
          <div class="item_info">
            <span style="max-width: 30px">
              <svg
                fill="#3852CE"
                viewBox="0 0 175 175"
                xml:space="preserve"
                x="0px"
                y="0px"
              >
                <path
                  d="M144.768,78.685c4.321-16.125,2.103-32.968-6.244-47.426C127.392,11.978,106.633,0,84.347,0
                  c-10.912,0-21.7,2.899-31.199,8.383c-14.458,8.347-24.8,21.825-29.121,37.951c-4.321,16.125-2.104,
                  32.968,6.244,47.426	c11.132,19.281,31.892,31.259,54.178,31.259c8.41,0,16.745-1.729,24.474-5.03l32.507,
                  56.303l12.99-7.5l-32.508-56.305	C133.087,104.133,141.102,92.37,144.768,78.685z
                  M108.148,103.646c-7.221,4.169-15.417,6.373-23.699,6.373
                  c-16.944,0-32.727-9.104-41.188-23.759c-6.344-10.988-8.029-23.789-4.745-36.043C41.8,37.96,49.66,27.717,60.648,21.373
                  C67.87,17.204,76.064,15,84.347,15c16.944,0,32.726,9.104,41.187,23.759c6.344,10.988,8.029,23.789,4.746,36.044
                  C126.996,87.059,119.136,97.302,108.148,103.646z M91.898,55.009h17.5v15h-17.5v17.5h-15v-17.5h-17.5v-15h17.5v-17.5h15V55.009z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slide",
  props: {
    slideData: {
      type: String
    }
  }
};
</script>
<style scoped lang="scss">
.photography {
  height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  @media screen and (min-width: 620px) {
    width: initial;
  }
  @media screen and (min-width: 960px) {
    flex: 0 0 320px;
    padding: 6px;
  }
  & .portfolio_item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    & .img-responsive {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 320px;
      object-fit: contain;
      @media screen and (min-width: 960px) {
        max-width: initial;
      }
    }
    & .portfolio_item_hover {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      transform: translate(-100%);
      opacity: 0;
      transition: all 0.2s ease-in-out;
      & .portfolio-border {
        & .item_info {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 10px;
          width: 100%;
          font-weight: bold;
          & span {
            display: block;
            color: #fff;
            font-size: 18px;
            margin: auto;
            transform: translateX(-100px);
            transition: all 0.2s ease-in-out 0.2s;
            opacity: 0;
          }
        }
      }
    }
    @media screen and (min-width: 960px) {
      &:hover {
        & .portfolio_item_hover {
          opacity: 1;
          transform: translateX(0);
        }
        & .portfolio-border {
          & .item_info {
            & em,
            & span {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}
</style>
