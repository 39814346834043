




































import { Component, Prop, Vue } from "vue-property-decorator";
import Tags from "@/components/tags/Tags.vue";
import { TagsInterface } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import TecFilter from "@/components/filter/TecFilter.vue";

@Component({
  components: { TecFilter, TecBtn, Tags },
})
export default class Filters extends Vue {
  @Prop({ default: undefined }) readonly selectList!: TagsInterface[];

  public addFilter(isSelected: string, id: number) {
    this.$emit("filterSelected", { isSelected, id });
  }
}
