<template>
  <v-fade-transition>
    <div class="modal" @wheel.prevent="" @click="close">
      <div class="modal-background"></div>
      <div class="modal-content">
        <v-container>
          <img :src="data" :alt="data" />
        </v-container>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: "ModalImage",
  props: {
    data: String
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.modal,
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
}
.modal {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  & .modal-background {
    background-color: rgba(255, 255, 255, 0.8);
  }
  & .modal-content {
    z-index: 100;
    position: relative;
    @media screen and (min-width: 960px) {
      max-width: 80%;
    }
    & img {
      max-width: 100%;
      max-height: 85vh;
    }
    &:after {
      content: "×";
      font-size: 35px;
      color: #3852ce;
      cursor: pointer;
      position: absolute;
      top: -40px;
      right: -40px;
      &:hover {
        color: #3852ce99;
      }
    }
  }
}
</style>

<style scoped>
>>> .container.project {
  position: fixed;
}
</style>
