<template>
  <section class="stack">
    <v-container class="title-container">
      <v-row
        no-gutters
        class="text-center title-form"
      >
        <v-col cols="12" md="8">
          <page-header
            :crumbs="$vuetify.breakpoint.mdAndUp ? crumbs : false"
            title="Технологический стек:"
          />
        </v-col>
        <v-col cols="12">
          <div class="article-content">
            <h3>PHP</h3>
            <ul>
              <li>Laravel</li>
              <li>Yii</li>
              <li>Symfony</li>
              <li>Silex</li>
              <li>Zend Framework</li>
              <li>PDO</li>
              <li>CodeIgniter</li>
            </ul>
            <h3>Java</h3>
            <ul>
              <li>Spring Boot</li>
              <li>Spring MVC</li>
              <li>Hibernate</li>
            </ul>
            <h3>Python</h3>
            <ul>
              <li>Django</li>
              <li>Keras</li>
              <li>TenzorFlow</li>
              <li>Flask</li>
              <li>Yargy</li>
              <li>Natasha</li>
              <li>Tesseract</li>
              <li>PyOCR</li>
              <li>Wand</li>
              <li>NumPy</li>
              <li>Matplotlib</li>
              <li>SciPy</li>
              <li>Pandas</li>
              <li>Scikit-learn</li>
              <li>StatsModels</li>
            </ul>
            <h3 class="top">Delphi</h3>
            <h3>Visual Basic</h3>
            <h3>VBScript</h3>
            <h3>Go</h3>
            <h3>C</h3>
            <h3>C++</h3>
            <ul>
              <li>Qt</li>
              <li>OpenCV</li>
              <li>STL</li>
              <li>Boost</li>
            </ul>
            <h3>#C</h3>
            <h3>Objectice-C</h3>
            <h3>1С</h3>
            <h3>ABAP (SAP)</h3>
            <h3>Javascript</h3>
            <h3>Typescript</h3>
            <ul>
              <li>jQuery</li>
              <li>AJAX</li>
              <li>Vue</li>
              <li>React.js</li>
              <li>Angular.js</li>
              <li>Redux</li>
              <li>node.js</li>
              <li>Gulp</li>
            </ul>
            <h3>Swift</h3>
            <h3>Kotlin</h3>
            <h3>Flutter</h3>
            <h3>Xamarin</h3>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from '@/components/pageHeader/PageHeader.vue';

  export default {
    name: 'Stack',
    components: {
      PageHeader
    },
    data () {
      return {
        crumbs: [
          {
            text: 'Главная',
            href: '/'
          },
          {
            text: 'Технологический стек',
            href: '/stack',
            disabled: true
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .stack {
    padding: 80px 0;
    .article-content {
    color: #3852ce;
    margin-top: 50px;
    text-align: left !important;
    & h3 {
      font-weight: 800;
      font-size: 24px;
      line-height: 18px;
      margin-top: 27px;
      margin-bottom: 20px;
      .top {
        margin-top: 0px !important;
      }
    }
    & > ul{
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      list-style: none !important;
    }
    & .step {
      font-weight: 800;
      font-size: 24px;
      line-height: 18px;
      color: #8697ff;
      margin-top: 27px;
      & ~ h3 {
        margin-top: 20px;
        line-height: 1;
        @media screen and (min-width: 620px) {
          margin-top: initial !important;
        }
      }
    }
  }
  }

</style>