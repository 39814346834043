























import { Component, Prop, Vue } from "vue-property-decorator";
import { CasesInterface, CompanyReview, Crumb } from "@/interfaces";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import Cases from "@/components/cases/Cases.vue";
import RatingButton from "@/components/ratingButton/RatingButton.vue";
import Review from "@/components/reviews/Review.vue";

@Component({
  components: { Review, RatingButton, Cases, PageHeader }
})
export default class ReviewPage extends Vue {
  @Prop() readonly review!: CompanyReview;
  @Prop() readonly crumbs!: Array<Crumb>;
  @Prop() readonly cases!: CasesInterface;
}
