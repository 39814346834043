












import { Component, Vue } from "vue-property-decorator";
import ReviewPage from "@/components/reviews/ReviewPage.vue"; // @ is an alias to /src
import { Meta } from "@/decorators/vue-meta-decorator";
import { namespace } from "vuex-class";
import { CompanyReview } from "@/interfaces";
import NotFound from "@/components/NotFound.vue";

const Reviews = namespace("Reviews");

@Component({
  components: {
    NotFound,
    ReviewPage
  }
})
export default class ReviewPageView extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: `${this.review ? this.review.author : ""} — TECMAN.RU`
    };
  }

  @Reviews.State
  public review!: CompanyReview;
  @Reviews.Action
  public getReview!: (alias: string) => void;

  public projectName!: string;

  mounted() {
    this.projectName = this.$route.path.split("/")[2];
    this.getReview(this.projectName);
  }
  public crumbs = [
    {
      text: "11",
      disabled: false,
      href: "/"
    },
    {
      text: "Отзывы",
      disabled: false,
      href: "/feedback"
    },
    {
      text: "...",
      disabled: true,
      href: ""
    }
  ];
  // public review = {
  //   title: "Отзыв о компании Деньгимигом",
  //   review: {
  //     title: "Деньгимигом",
  //     txt: "“Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”",
  //     imgSrc: "../../assets/default_img.svg",
  //     user: {username: "Константин", position: "заместитель директора по развитию компании Деньгимигом"},
  //     rating: [
  //       {
  //         rate: 5,
  //         title: "Удовлетвороенность"
  //       },
  //       {
  //         rate: 5,
  //         title: "Профессионализм"
  //       },
  //       {
  //         rate: 5,
  //         title: "Сроки"
  //       },
  //       {
  //         rate: 5,
  //         title: "Качество"
  //       },
  //     ],
  //     link: "dengimigom.ru",
  //     teg: "#desktop #МФО #хэштег #разработка",
  //     selects: {
  //       industry: "Автомобильные сигнализации",
  //       technology: "Adobe Air",
  //       solution: "Мобильная разработка",
  //       platform: "iOS, Android"
  //     }
  //   }
  // }
  public cases = {
    imgSRC: "dengimigom.png",
    projectType: "CRM-системы под микрофинансовые огранизации\n",
    name: "деньгимигом",
    description:
      "Деньгимигом – одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.\n",
    path: "/project/dengimigom"
  };
}
