<template>
  <section class="slider">
    <div class="slider-container">
      <div class="slider__header">
        <div class="_header"></div>
      </div>
      <div class="slider-carousel">
        <div
          :style="{ left: `${offsetX}px` }"
          :class="['slider__line', { center: isCenterScreen }]"
        >
          <slide
            v-for="slide in slider"
            :key="slide"
            :slide-data="slide"
            @imgClick="showImg"
          />
        </div>
      </div>
      <div class="slider__controls">
        <div
          :class="['previous', !disableControls ? '' : 'disabled']"
          @click="!disableControls ? increase() : ''"
        >
          <i>
            <svg
              width="39"
              height="15"
              viewBox="0 0 39 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39 7.5H1.5M1.5 7.5L6.5 14.5M1.5 7.5L6.5 1"
                :stroke="!disableControls ? '#3852CE' : '#CBCBCB'"
              />
            </svg>
          </i>
        </div>
        <div
          :class="['next', !disableControls ? '' : 'disabled']"
          @click="!disableControls ? decrease() : ''"
        >
          <i>
            <svg
              width="39"
              height="15"
              viewBox="0 0 39 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 7.5H37.5M37.5 7.5L32.5 14.5M37.5 7.5L32.5 1"
                :stroke="!disableControls ? '#3852CE' : '#CBCBCB'"
              />
            </svg>
          </i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slide from "@/components/imageCarousel/slide.vue";

export default {
  name: "ImageCarousel",
  components: { Slide },
  props: {
    slider: {
      type: Array
    }
  },
  data() {
    return {
      offsetX: 0,
      screenSize: 0,
      isCenterScreen: false
    };
  },
  computed: {
    disableControls() {
      return this.screenSize > this.slider.length * 320;
    }
  },
  created() {
    this.screen();
    window.addEventListener("resize", this.screen);
  },
  destroyed() {
    window.removeEventListener("resize", this.screen);
  },
  methods: {
    showImg(slideData) {
      this.$emit("imgClick", slideData);
    },
    screen() {
      this.screenSize = window.innerWidth;
      if (this.screenSize > this.slider.length * 320) {
        this.offsetX = 0;
        this.isCenterScreen = true;
      } else this.isCenterScreen = false;
    },
    increase() {
      if (this.screenSize > 620) {
        this.offsetX += 320;
        if (this.offsetX > 0) {
          this.offsetX = this.screenSize - this.slider.length * 320;
        }
      } else {
        this.offsetX += this.screenSize;
        if (this.offsetX > 0) {
          this.offsetX = (this.slider.length - 1) * -this.screenSize;
        }
      }
    },
    decrease() {
      if (this.screenSize > 620) {
        this.offsetX -= 320;
        if (this.offsetX < this.screenSize - this.slider.length * 320) {
          this.offsetX = 0;
        }
      } else {
        this.offsetX -= this.screenSize;
        if (this.offsetX < (this.slider.length - 1) * -this.screenSize) {
          this.offsetX = 0;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.slider {
  //height: 55vh;
  overflow: hidden;
  width: 100%;
  & .slider-container {
    & .slider-carousel {
      //height: 40vh;
      width: 100%;
      overflow: hidden;
      & .slider__line {
        position: relative;
        left: 0;
        height: inherit;
        display: flex;
        transition: all 0.5s ease-in-out;
        justify-content: left;
        &.center {
          justify-content: center !important;
        }
      }
    }
    & .slider__header {
      display: flex;
      & ._header {
        width: 73.3333%;
      }
    }
    & .slider__controls {
      height: 100%;

      display: flex;
      margin: 20px auto 0;
      width: fit-content;
      & .previous,
      & .next {
        position: relative;
        width: 63px;
        height: 63px;
        background: #eff2ff;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.disabled {
          background: #f4f4f4;
          cursor: auto;
        }
        & i {
          height: 20px;
          margin: auto;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          transition: all 0.3s;
          left: 0;
          top: 0;
        }
      }
      & .next {
        margin: 0 0 0 80px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .slider {
    //height: 350px;
    margin: 0;
  }

  .slider__header {
    margin-bottom: 20px;
  }

  .slider ._header {
    display: flex;
  }

  .slider-carousel {
    //height: 250px;
  }
}
</style>

<!-- change color on hover
.previous:hover .left__arrow:before,
  .next:hover .right__arrow:before {
    border-left-color: #ffbf00;
    border-right-color: #ffbf00;
  }
-->
