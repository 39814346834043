import { render, staticRenderFns } from "./ModalImage.vue?vue&type=template&id=45369670&scoped=true&"
import script from "./ModalImage.vue?vue&type=script&lang=js&"
export * from "./ModalImage.vue?vue&type=script&lang=js&"
import style0 from "./ModalImage.vue?vue&type=style&index=0&id=45369670&scoped=true&lang=scss&"
import style1 from "./ModalImage.vue?vue&type=style&index=1&id=45369670&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45369670",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VContainer,VFadeTransition})
