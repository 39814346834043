


















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyReview, Crumb, ProjectData, SearchSelects } from "@/interfaces";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import Review from "@/components/reviews/Review.vue";
import ProjectCalc from "@/components/projectСalculation/ProjectCalc.vue";
import { i18n } from "@/main";

@Component({
  components: { ProjectCalc, Review, PageHeader }
})
export default class Project2 extends Vue {
  @Prop({
    default: () => [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/"
      },
      {
        text: i18n.t("title.titleCases"),
        disabled: false,
        href: "/projects"
      },
      {
        text: "Проект Ависто",
        disabled: true,
        href: ""
      }
    ]
  })
  crumbs?: Array<Crumb>;

  @Prop() readonly projectData!: ProjectData;
  @Prop() readonly review!: CompanyReview;

  public slidersSize() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      let temp: any = this.$refs.slide;
      if (temp !== undefined) {
        const { clientHeight } = temp.$el;
        temp = this.$refs.slider;
        temp.style.height = `${clientHeight}px`;
      }
    }
  }

  // public review = {
  //   title: "Деньгимигом",
  //   txt: "“Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”",
  //   imgSrc: "@/assets/default_img.svg",
  //   user: {username: "Константин", position: "заместитель директора по развитию компании Деньгимигом"},
  //   rating: [
  //     {
  //       rate: 5,
  //       title: "Удовлетвороенность"
  //     },
  //     {
  //       rate: 5,
  //       title: "Профессионализм"
  //     },
  //     {
  //       rate: 5,
  //       title: "Сроки"
  //     },
  //     {
  //       rate: 5,
  //       title: "Качество"
  //     },
  //   ],
  //   link: "dengimigom.ru",
  //   teg: "#desktop #МФО #хэштег #разработка",
  //   selects: {
  //     industry: "Автомобильные сигнализации",
  //     technology: "Adobe Air",
  //     solution: "Мобильная разработка",
  //     platform: "iOS, Android"
  //   }
  // }
  public selects: Array<SearchSelects> = [
    {
      title: "Отрасль",
      select: "Автомобильные сигнализации"
    },
    {
      title: "Технология",
      select: "Adobe Air"
    },
    {
      title: "Решение",
      select: "Мобильная разработка"
    },
    {
      title: "Платформа",
      select: "iOS, Android"
    }
  ];
}
