

































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import PageHeader from "../pageHeader/PageHeader.vue";
import {
  CompanyReview,
  Crumb,
  SearchSelects,
  TagsInterface,
  Chips,
} from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import TecFilter from "@/components/filter/TecFilter.vue";
import ReviewItem from "@/components/reviews/ReviewItem.vue";
import Tags from "@/components/tags/Tags.vue";
import { i18n } from "@/main";

@Component({
  components: { ReviewItem, TecFilter, TecBtn, PageHeader, Tags },
})
export default class ReviewsList extends Vue {
  @Prop() readonly crumbs!: Array<Crumb>;
  @Prop() readonly reviews!: Array<CompanyReview>;
  @Prop({ default: undefined }) readonly selectList!: SearchSelects[];
  @Prop({ default: undefined }) readonly tags!: TagsInterface[];

  get title(): string {
    return `${i18n.t("title.feedback")}`;
  }
  get placeholder(): string {
    return `${i18n.t("field.placeholder")}`;
  }
  public text = "";
  public name = "";
  public loading = false;
  public isSendingSuccess = false;
  public sheet = false;
  get chips(): Chips[] {
    return [
      { title: i18n.t("chips.satisfaction"), rating: 5, id: "satisfaction" },
      {
        title: i18n.t("chips.professionalism"),
        rating: 5,
        id: "professionalism",
      },
      { title: i18n.t("chips.deadlines"), rating: 5, id: "timing" },
      { title: i18n.t("chips.quality"), rating: 5, id: "quality" },
    ];
  }

  public activepage = 1;
  public pages = 1;

  public sendReview() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.text = "";
      this.name = "";
      this.isSendingSuccess = true;
      this.sheet = true;
      this.chips.forEach((item: { rating: number }) => {
        item.rating = 5;
      });
    }, 1000);
  }

  public makeReview() {
    this.$emit("leaveReview");
  }
  public openFilters() {
    this.$emit("openFilters");
  }

  public inputReview(data: CompanyReview) {
    this.$emit("inputReview", data);
  }

  // filtering methods
  public addFilter(isSelected: string, id: number) {
    this.$emit("filterSelected", { isSelected, id });
  }

  public deleteFilter(item: SearchSelects) {
    this.$emit("filterSelected", { isSelected: false, id: item.id });
  }

  public selectTag(newVal: TagsInterface) {
    this.$emit("selectTag", newVal);
  }

  public showAllTags() {
    this.$emit("showAllTags");
  }
}
