



















import { Component, Vue } from "vue-property-decorator";
import { Meta } from "@/decorators/vue-meta-decorator";
import Project from "@/components/Project.vue";
import { CasesInterface, CompanyReview, ProjectData } from "@/interfaces";
import Project2 from "@/components/project2.vue";
import { namespace } from "vuex-class";
import NotFound from "@/components/NotFound.vue";
import ModalImage from "@/components/modal/ModalImage.vue";
import { i18n } from "@/main";

const Reviews = namespace("Reviews");
const projects = namespace("Projects");

@Component({
  components: {
    ModalImage,
    NotFound,
    Project2,
    Project,
  },
})
export default class ProjectPage extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: `${
        this.selectedProject !== null
          ? this.selectedProject.title?.replace("<br>", " ")
          : ""
      } — TECMAN.RU`,
    };
  }
  @Reviews.State
  public review!: CompanyReview;
  @projects.State
  public selectedProject!: CasesInterface | null;
  @projects.Action
  public updateSelectedProject!: (newVal: CasesInterface | null) => void;
  @projects.Action
  public getProjectByAlias!: (alias: string) => void;

  get crumbs() {
    return [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/",
        link: true,
      },
      {
        text: i18n.t("title.titleCases"),
        disabled: false,
        href: "/projects",
        link: true,
      },
      {
        text: `${
          this.selectedProject !== null
            ? this.selectedProject.title?.replace("<br>", " ")
            : ""
        }`,
        disabled: true,
        href: "",
      },
    ];
  }

  private body = document.body;

  public slideData: string | null = null;

  public showImg(slideData: string) {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.slideData = slideData;
      // this.body.classList.add('noscroll')
    }
  }

  public closeModal() {
    this.slideData = null;
    // this.body.classList.remove('noscroll')
  }
  mounted() {
    this.getProjectByAlias(this.$route.path.split("/")[2]);
  }
  updated() {
    this.getProjectByAlias(this.$route.path.split("/")[2]);
  }
}
