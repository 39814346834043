







import { Component, Vue } from "vue-property-decorator";
import Article from "@/components/Article.vue";
import { Meta } from "@/decorators/vue-meta-decorator";

import { namespace } from "vuex-class";
import { TecArticle } from "@/interfaces";
import NotFound from "@/components/NotFound.vue";
import { i18n } from "@/main";

const articles = namespace("Articles");

@Component({
  components: {
    NotFound,
    Article,
  },
})
export default class ArticleVue extends Vue {
  @Meta
  myMetaConfig() {
    return {
      title: `${i18n.t("title.article")} -  ${
        this.article ? this.article.title : ""
      } — TECMAN.RU`,
    };
  }
  @articles.State
  public articles!: Array<TecArticle>;
  @articles.Action
  public getArticles!: () => void;

  get article() {
    if (this.articles.length > 0) {
      const articleAlias = this.$route.path.split("/")[2];
      return this.articles.filter((item: any) =>
        item.alias.match(articleAlias)
      )[0];
    } else {
      return null;
    }
  }
  get crumbs(): object {
    return [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/",
      },
      {
        text: i18n.t("title.articles"),
        disabled: false,
        href: "/articles",
      },
      {
        text: "...",
        disabled: true,
        href: "",
      },
    ];
  }

  mounted() {
    if (this.articles.length === 0) {
      this.getArticles();
    }
  }
  updated() {
    this.getArticles();
  }
}
