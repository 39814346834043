var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"project-preview",attrs:{"color":!hover ? '#A0ACE8' : 'transparent',"height":"100%","outlined":"","rounded":"0"},on:{"click":function($event){return _vm.$emit('inputCard', _vm.card)}}},[_c('div',{staticClass:"fool",style:(_vm.card.background
          ? { background: _vm.card.background }
          : { backgroundColor: 'white' })},[_c('v-img',{staticClass:"fool",attrs:{"src":_vm.card.preview}},[_c('p',{class:[
            _vm.card.background !== 'white' ? 'white--text' : 'base-color',
            'type',
            'mt-6',
            'mb-1' ]},[_vm._v(" "+_vm._s(_vm.card.projectType)+" ")]),_c('h4',{staticClass:"name",class:[
            _vm.card.background !== 'white' ? 'white--text' : 'base-color',
            'text-center' ]},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"transition-fast-in-fast-out darken-2 v-card--reveal",staticStyle:{"height":"100%"},style:({ backgroundColor: _vm.card.color })},[_c('p',{staticClass:"white--text type mt-6 mb-1"},[_vm._v(_vm._s(_vm.card.projectType))]),_c('h4',{staticClass:"white--text text-center name"},[_vm._v(_vm._s(_vm.card.name))]),_c('p',{staticClass:"white--text text-center px-4 desc"},[_vm._v(_vm._s(_vm.card.description))])]):_vm._e()])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }