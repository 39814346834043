






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class RatingButton extends Vue {
  @Prop() readonly stars!: string;
  @Prop() readonly rClass!: string;
  @Prop({ default: true }) readonly bordered!: boolean;
  @Prop({ default: "" }) readonly background!: string;
  @Prop({ default: false }) readonly isNumber!: boolean;
}
