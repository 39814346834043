
















import { Component, Vue } from "vue-property-decorator";
import { Meta } from "@/decorators/vue-meta-decorator";
import Projects from "@/components/Projects.vue";
import {
  CasesInterface,
  CompanyReview,
  SearchSelects,
  Selects,
  TagsInterface,
} from "@/interfaces";
import { namespace } from "vuex-class";
import { i18n } from "@/main";

const filters = namespace("Filters");
const projects = namespace("Projects");
const Reviews = namespace("Reviews");

@Component({
  components: {
    Projects,
  },
})
export default class ProjectsPage extends Vue {
  @filters.State
  public selectList!: SearchSelects;
  @filters.Action
  public updateSelectedItem!: (payload: {
    SelectedItem: Selects;
    id: number;
  }) => void;
  @projects.Action
  public getProjects!: () => void;
  @projects.State
  public cards!: Array<CasesInterface>;
  @projects.State
  public tags!: Array<TagsInterface>;
  @projects.Action
  public updateTags!: (newVal: TagsInterface[]) => void;
  @projects.Action
  public updateSelectedProject!: (newVal: CasesInterface | null) => void;
  @Reviews.Action
  public updateReview!: (newVal: CompanyReview) => void;
  @Reviews.Action
  public delReview!: () => void;
  @filters.Action
  public updateSelectionList!: () => void;

  @Meta
  myMetaConfig() {
    return {
      title: `${i18n.t("title.portfolio")} — TECMAN.RU`,
    };
  }

  public showAllTags() {
    const allTags = this.tags.map(function (item: TagsInterface) {
      item.isSelected = true;
      return item;
    });
    this.updateTags(allTags);
    setTimeout(this.getProjects, 100);
  }
  public resetTags() {
    const allTags = this.tags.map(function (item: TagsInterface) {
      item.isSelected = false;
      return item;
    });
    this.updateTags(allTags);
    setTimeout(this.getProjects, 100);
  }

  public redirectToCase(card: CasesInterface) {
    this.updateSelectedProject(card);
    if (card.reviews && card.reviews.length > 0) {
      this.updateReview(card.reviews[0]);
    } else this.delReview();
    this.$router.push(`/project/${card.path}`);
  }

  public updTags(newVal: TagsInterface) {
    const lcltabs = this.tags.map(function (item: TagsInterface) {
      return item.name.match(newVal.name) ? newVal : item;
    });
    this.updateTags(lcltabs);
    setTimeout(this.getProjects, 1000);
  }
  get crumbs(): object {
    return [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/",
      },
      {
        text: i18n.t("title.titleCases"),
        disabled: false,
        href: "/projects",
      },
    ];
  }

  mounted() {
    this.getProjects();
    this.updateSelectionList();
  }
  updated() {
    this.getProjects();
    this.updateSelectionList();
  }
}
