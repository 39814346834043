
















































import { Component, Prop, Vue } from "vue-property-decorator";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import { CasesInterface, Crumb } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import Cases from "@/components/cases/Cases.vue";
import { i18n } from "@/main";

@Component({
  components: { Cases, TecBtn, PageHeader }
})
export default class NotFound extends Vue {
  get title(): string {
    return `${i18n.t("notFound.title")}`;
  }
  @Prop({
    default: () => [
      {
        text: i18n.t("title.home"),
        disabled: false,
        href: "/"
      },
      {
        text: "...",
        disabled: true,
        href: ""
      }
    ]
  })
  crumbs?: Array<Crumb>;
  @Prop({
    default: () => {
      return {
        imgSRC: "dengimigom.png",
        projectType: i18n.t("dm.projectType"),
        name: i18n.t("dm.name"),
        description: i18n.t("dm.descr")
      };
    }
  })
  readonly cases?: CasesInterface;
}
