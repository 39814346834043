









































import { Component, Prop, Vue } from "vue-property-decorator";
import Tags from "@/components/tags/Tags.vue";
import { TagsInterface } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn, Tags },
})
export default class Filters extends Vue {
  @Prop({ default: undefined }) readonly tags!: TagsInterface[];

  public selectTag(newVal: TagsInterface) {
    this.$emit("selectTag", newVal);
  }
  public showAllTags() {
    this.$emit("showAllTags");
  }
}
